@font-face {
  font-family: "arodora pro";
  src: url("../public/font.woff2");
}

@font-face {
  font-family: "graphik";
  src: url("../public/Graphik-Light.otf");
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  overflow: hidden;
}

body {
  font-family: "graphik";
  background-color: black;
  color: white;
  mix-blend-mode: difference;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  outline: none;
  z-index: -1;
  opacity: 0;
}

header {
  width: 100vw;
  height: 10vh;
  height: calc(var(--vh, 1vh) * 10);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header_left {
  padding: 32px;
  font-family: "arodora pro";
  font-size: 26px;
  opacity: 0;
}

.header_right {
  padding: 32px;
  text-align: end;
}

main {
  width: 100vw;
  height: 80vh;
  height: calc(var(--vh, 1vh) * 80);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.main_container {
  margin-left: 180px;
}

.description {
  font-size: 32px;
  transform: translateY(40px);
}

.oh {
  overflow: hidden;
}

footer {
  width: 100vw;
  height: 10vh;
  height: calc(var(--vh, 1vh) * 10);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.text {
  font-size: 12px;
}

.footer_left {
  padding: 32px;
  opacity: 0;
}

.footer_right {
  padding: 32px;
  text-align: end;
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .main_container {
    margin-left: 32px;
  }

  .description {
    font-size: 24px;
  }

  .header_left {
    padding: 16px;
  }

  .footer_left {
    padding: 16px;
  }

  .footer_right {
    padding: 16px;
  }
}
